import { getCurrentDate, isTheSameDates } from '@/helpers/dates'
import { ref, computed, watch } from 'vue'
import { defineStore } from 'pinia'
import { AxiosResponse } from 'axios'
import { merge, pick, set } from 'lodash'
import { useDebounceFn } from '@vueuse/core'

import {
  CommonListResponse,
  Currency,
  Repository,
  RepositoryPost,
  RepositoryPut,
  RepositoryInvite,
  RepositoryInvitePost,
  RepositoryCollaborator,
  RepositoryStatus,
  RepositoryTimeline,
  RepositoryUISettings,
} from '@types'

import {
  ACCESS_TYPE_READ_WRITE,
  REPOSITORY_DEFAULT_TIMELINE,
} from '@/const/repositories'
import { DEBOUNCE_DELAY, NOTIFICATION_DELAY } from '@/const/common'
import { ACCESS_TYPE_OWNER, ACCESS_TYPE_READONLY } from '@/const/repositories'

import { getCurrencySymbol } from '@/helpers/common'
import {
  addStoreListItem,
  prepareResponseError,
  removeStoreListItem,
  updateStoreListItem,
} from './utils/helpers'

import { NotificationInstance, useNotifications } from '@/plugins/notification'

import api from '@/store/api'
import useAnalyticsStore from '@/store/analytics'
import { useAssetsStore } from '@/store/assets'
import { useMainStore } from '@/store/main'
import { useUserSettingsStore } from '@/store/user/settings'

type RepositoriesApiResponse = CommonListResponse<Repository[]> | undefined
type CurrenciesApiResponse = Currency[]
type RepositoryInvitesApiResponse = RepositoryInvite[]
type RepositoryCollaboratorsApiResponse = RepositoryCollaborator[]

export const useRepositoriesStore = defineStore('repositories', () => {
  const analyticsStore = useAnalyticsStore()
  const mainStore = useMainStore()
  const assetsStore = useAssetsStore()
  const userSettingsStore = useUserSettingsStore()

  const { progress, remove, update } = useNotifications()

  const currentRepositoryId = computed<string | undefined>({
    get() {
      return list.value.length === 0
        ? undefined
        : userSettingsStore.getLastActiveRepository
    },
    set(value: string | undefined) {
      if (value === userSettingsStore.getLastActiveRepository) return
      userSettingsStore.setLastActiveRepository(value)
    },
  })
  const list = ref<Repository[]>([])
  const currenciesList = ref<Currency[]>([])
  const invitesList = ref<RepositoryInvite[]>([])
  const collaboratorsList = ref<(RepositoryInvite | RepositoryCollaborator)[]>(
    [],
  )

  const initFlag = ref(false)
  const loadingList = ref(true)
  const loadingAction = ref(false)
  const loadingInvites = ref(true)
  const loadingCollaborators = ref(true)
  const loadingCollaboratorsAction = ref(false)

  const deleteCache = ref(false)

  const internalUISettings = ref<RepositoryUISettings>()

  const getCurrentRepository = computed(() => {
    if (!list.value) return
    return list.value.find(item => item.id === currentRepositoryId.value)
  })
  const getCurrentRepositoryName = computed(
    () => getCurrentRepository.value?.name,
  )
  const getCurrentRepositoryCurrency = computed(
    () =>
      getCurrentRepository.value?.currency ||
      currenciesList.value?.[0]?.ticker ||
      '',
  )
  const getCurrentRepositoryFormattedCurrency = computed(
    () =>
      getCurrentRepositoryCurrency.value &&
      getCurrencySymbol(getCurrentRepositoryCurrency.value),
  )
  const getCurrentTimeline = computed(
    () =>
      pick(
        getCurrentRepository.value?.user_repo_settings,
        'periods',
        'freq',
        'end',
        'include_end',
        'end_is_today',
      ) as RepositoryTimeline,
  )

  const getTimelineEnd = computed(() => {
    if (getCurrentTimeline.value.end_is_today === true) {
      return getCurrentDate({})
    } else {
      return getCurrentTimeline.value?.end
    }
  })

  const getCurrentRepositorySettings = computed(
    () => getCurrentRepository.value?.settings || null,
  )

  const getCollaboratorsList = computed(() =>
    collaboratorsList.value.map(collaborator => ({
      ...collaborator,
      ...(collaborator.access_type === ACCESS_TYPE_OWNER
        ? { status: RepositoryStatus.OWNER }
        : {}),
    })),
  )

  const getCurrenciesList = computed(() =>
    currenciesList.value.map(item => ({
      ...item,
      name: `${item.name} - ${item.ticker}`,
    })),
  )

  const isOwner = computed(() => {
    if (!getCurrentRepository.value) return false
    return (
      ACCESS_TYPE_OWNER ===
      getCurrentRepository.value?.user_repo_settings?.access_type
    )
  })

  const isReadonly = computed(() => {
    if (!getCurrentRepository.value) return false
    return (
      !getCurrentRepository.value?.user_repo_settings?.access_type ||
      ACCESS_TYPE_READONLY ===
        getCurrentRepository.value?.user_repo_settings?.access_type
    )
  })

  const isReadWrite = computed(() => {
    if (!getCurrentRepository.value) return false
    return (
      ACCESS_TYPE_READ_WRITE ===
      getCurrentRepository.value?.user_repo_settings?.access_type
    )
  })

  const setCurrentRepositoryId = async (id?: string) => {
    if (mainStore.getBlockers.length) {
      for await (const blocker of mainStore.getBlockers) {
        try {
          await blocker()
        } catch (e) {
          return
        }
      }
      mainStore.clearBlockers()
    }
    if (id) {
      currentRepositoryId.value = id
    }

    if (
      currentRepositoryId.value &&
      list.value.find(item => item.id === currentRepositoryId.value)
    )
      return

    if (list.value.length > 0) {
      currentRepositoryId.value = list.value?.[0]?.id
    }
  }

  const fetchRepositories = async (): Promise<void> => {
    loadingList.value = true
    try {
      const result = await api.get<RepositoriesApiResponse>('repositories')
      initFlag.value = true
      list.value = result.data?.data || []
    } catch (e) {
      throw Error(prepareResponseError(e))
    } finally {
      loadingList.value = false
    }
  }
  const fetchCurrencies = async (): Promise<void> => {
    const result = await api.get<CurrenciesApiResponse>('currencies')
    currenciesList.value = result.data
  }
  const fetchRepositoryInvites = async (): Promise<void> => {
    loadingInvites.value = true
    try {
      const result = await api.get<RepositoryInvitesApiResponse>('invites')
      invitesList.value = result.data || []
    } catch (e) {
      throw Error(prepareResponseError(e))
    } finally {
      loadingInvites.value = false
    }
  }
  const fetchRepositoryCollaborators = async (
    id: string,
    signal?: AbortSignal,
  ): Promise<void> => {
    loadingCollaborators.value = true
    try {
      const result = await api.get<RepositoryCollaboratorsApiResponse>(
        `collaborators?repository_id=${id}`,
        { signal },
      )
      collaboratorsList.value = result.data
    } catch (e) {
      throw Error(prepareResponseError(e))
    } finally {
      loadingCollaborators.value = false
    }
  }
  const createRepository = async (
    repository: RepositoryPost,
    showNotify = true,
  ): Promise<Repository | undefined> => {
    loadingAction.value = true
    let nid: NotificationInstance | undefined
    if (showNotify) {
      nid = await progress({
        message: 'Creating repository',
      })
    }
    try {
      const result = await api.post<
        Repository,
        AxiosResponse<Repository>,
        RepositoryPost
      >('repositories', {
        ...repository,
        user_repo_settings: {
          ...REPOSITORY_DEFAULT_TIMELINE,
          ui_settings: null,
        },
      })
      const id = result.data.id
      if (id) {
        await api.post(`repositories/${id}/initialize`)
      }
      addStoreListItem<Repository>(list.value, result.data, 'name')
      setCurrentRepositoryId()
      if (nid) {
        await update(
          nid,
          {
            type: 'success',
            message: `Repository ${result.data.name} created`,
          },
          NOTIFICATION_DELAY,
        )
      }
      return result.data
    } catch (e) {
      if (nid) await remove(nid)
      throw Error(prepareResponseError(e))
    } finally {
      loadingAction.value = false
    }
  }
  const updateRepository = async (
    repository: RepositoryPut,
    showNotify = true,
  ): Promise<Repository | undefined> => {
    if (!repository.user_repo_settings) return
    loadingAction.value = true
    let nid
    if (showNotify) {
      nid = await progress({
        message: 'Updating repository',
      })
    }
    try {
      const result = await api.put<
        Repository,
        AxiosResponse<Repository>,
        RepositoryPut
      >(`repositories/${repository.id}`, repository)
      if (repository.currency !== getCurrentRepositoryCurrency.value) {
        analyticsStore.markAsDeprecated()
      }
      updateStoreListItem<Repository>(list.value, result.data)
      assetsStore.addAddiotionCreatedCurrency(result.data.currency_asset)
      if (nid) {
        await update(
          nid,
          {
            type: 'success',
            message: `Repository ${result.data.name} updated`,
          },
          NOTIFICATION_DELAY,
        )
      }
      return result.data
    } catch (e) {
      if (nid) await remove(nid)
      throw Error(prepareResponseError(e))
    } finally {
      loadingAction.value = false
    }
  }

  const updateUISettings = (key: string | string[], data: any) => {
    if (!internalUISettings.value) {
      internalUISettings.value = {}
    }
    set(internalUISettings.value, key, data)
    onSettingsUpdate()
  }

  const onSettingsUpdate = useDebounceFn(() => {
    updateSettings({ ui_settings: internalUISettings.value })
  }, DEBOUNCE_DELAY)

  const updateSettings = async (settings: Record<string, any>) => {
    try {
      const repository = getCurrentRepository.value
      if (!repository) return
      let data = repository.user_repo_settings || {}
      data = merge(data, settings)
      updateStoreListItem<Repository>(list.value, {
        ...repository,
        user_repo_settings: data,
      })
      await api.put(`repositories/${repository?.id}/user_repo_settings`, data)
    } catch (e) {
      throw Error(prepareResponseError(e))
    }
  }

  const exportRepository = async (rid: string): Promise<string> => {
    const result = await api.get(`repositories/${rid}/export`)
    return JSON.stringify(result.data, null, 2)
  }

  const deleteRepository = async (
    id: string,
    showNotify = true,
  ): Promise<void> => {
    loadingAction.value = true
    let nid: NotificationInstance | undefined
    if (showNotify) {
      nid = await progress({
        message: 'Deleting repository',
      })
    }
    try {
      if (id === currentRepositoryId.value) {
        await analyticsStore.clear(false)
        await mainStore.clearState()
      }
      await api.delete(`repositories/${id}`)
      removeStoreListItem<Repository>(list.value, id)
      if (id === currentRepositoryId.value) {
        currentRepositoryId.value = undefined
      }
      setCurrentRepositoryId()
      if (nid) {
        await update(
          nid,
          {
            type: 'success',
            message: 'Repository deleted',
          },
          NOTIFICATION_DELAY,
        )
      }
    } catch (e) {
      if (nid) await remove(nid)
      throw Error(prepareResponseError(e))
    } finally {
      loadingAction.value = false
    }
  }
  const addRepositoryInvite = async (
    params: RepositoryInvitePost,
  ): Promise<RepositoryInvite | undefined> => {
    loadingCollaboratorsAction.value = true
    const nid = await progress({
      message: 'Sharing repository',
    })
    try {
      const result = await api.post<
        RepositoryInvite,
        AxiosResponse<RepositoryInvite>,
        RepositoryInvitePost
      >('invites', params)
      const data = result.data
      const collaborator = collaboratorsList.value.find(
        item => item.email === data.email,
      )
      if (collaborator) {
        updateStoreListItem<any>(
          collaboratorsList.value,
          { status: RepositoryStatus.PENDING },
          'email',
        )
      } else {
        addStoreListItem<any>(
          collaboratorsList.value,
          {
            ...data,
            user: { email: data.email },
          },
          'email',
        )
      }
      const repository = list.value.find(item => item.id === data.repository.id)
      if (repository) {
        repository.collaborators_count++
        updateStoreListItem<Repository>(list.value, repository)
      }
      await update(
        nid,
        {
          type: 'success',
          message: `Repository shared with ${data.email}`,
        },
        NOTIFICATION_DELAY,
      )
      return result.data
    } catch (e) {
      await remove(nid)
      throw Error(prepareResponseError(e))
    } finally {
      loadingCollaboratorsAction.value = false
    }
  }
  const decreaseRepositoryColaboratorsCount = (repositoryId: string) => {
    const repository = list.value.find(item => item.id === repositoryId)
    if (repository) {
      repository.collaborators_count--
      updateStoreListItem<Repository>(list.value, repository)
    }
  }
  const removeRepositoryInvite = async (
    id: string,
    repositoryId: string,
  ): Promise<void> => {
    loadingCollaboratorsAction.value = true
    try {
      await api.delete(`invites/${id}`)
      removeStoreListItem<any>(collaboratorsList.value, id)
      decreaseRepositoryColaboratorsCount(repositoryId)
    } catch (e) {
      throw Error(prepareResponseError(e))
    } finally {
      loadingCollaboratorsAction.value = false
    }
  }
  const updateRepositoryCollaborator = async (
    collaboratorId: string,
    repositoryId: string,
    data: Partial<RepositoryCollaborator>,
  ): Promise<void> => {
    loadingCollaboratorsAction.value = true
    try {
      const response = await api.put<RepositoryCollaborator>(
        `collaborators/${collaboratorId}?repository_id=${repositoryId}`,
        data,
      )
      updateStoreListItem<any>(collaboratorsList.value, response.data, 'email')
    } catch (e) {
      throw Error(prepareResponseError(e))
    } finally {
      loadingCollaboratorsAction.value = false
    }
  }
  const removeRepositoryCollaborator = async (
    email: string,
    collaboratorId: string,
    repositoryId: string,
    removeRepository = false,
  ): Promise<void> => {
    loadingCollaboratorsAction.value = true
    try {
      await api.delete(
        `collaborators/${collaboratorId}?repository_id=${repositoryId}`,
      )
      if (removeRepository) {
        updateStoreListItem<Repository>(list.value, { id: repositoryId })
      } else {
        removeStoreListItem<any>(collaboratorsList.value, email, 'email')
        decreaseRepositoryColaboratorsCount(repositoryId)
      }
    } catch (e) {
      throw Error(prepareResponseError(e))
    } finally {
      loadingCollaboratorsAction.value = false
    }
  }
  const updateInviteInList = (id: string, accepted: boolean) => {
    const invite = invitesList.value.find(item => item.id === id)
    if (!invite) return false
    updateStoreListItem<RepositoryInvite>(invitesList.value, {
      ...invite,
      accepted,
    })
    return true
  }
  const acceptRepositoryInvite = async (id: string): Promise<void> => {
    const result = updateInviteInList(id, true)
    if (!result) return
    await api.put(`invites/${id}/accept`)
  }
  const declineRepositoryInvite = async (id: string): Promise<void> => {
    const result = updateInviteInList(id, false)
    if (!result) return
    await api.put(`invites/${id}/decline`)
  }

  const updateTimeline = async (
    timeline: RepositoryTimeline | undefined,
    isEndChanged = false,
  ) => {
    if (timeline === undefined) return
    let end_is_today = timeline.end_is_today
    if (isEndChanged) {
      analyticsStore.setDate(undefined)
      end_is_today = !!isTheSameDates(getCurrentDate({}), timeline.end)
    }
    await updateSettings({
      ...timeline,
      end_is_today,
    })
  }

  const clearCache = async (repositoryId: string) => {
    deleteCache.value = true
    try {
      await api.delete(`cache/analytics/${repositoryId}`)
    } catch (e) {
      throw Error(prepareResponseError(e))
    } finally {
      deleteCache.value = false
    }
  }

  watch(currentRepositoryId, () => {
    const repository = getCurrentRepository.value
    internalUISettings.value = repository?.user_repo_settings?.ui_settings
  })

  const clearRepositories = () => {
    initFlag.value = false
    loadingList.value = true
    loadingAction.value = false
    loadingInvites.value = true
    loadingCollaborators.value = true
    loadingCollaboratorsAction.value = false

    list.value = []
    currenciesList.value = []
    invitesList.value = []
    collaboratorsList.value = []
  }
  return {
    currentRepositoryId,
    list,
    currenciesList,
    invitesList,
    collaboratorsList,
    initFlag,
    loadingList,
    loadingAction,
    loadingInvites,
    loadingCollaborators,
    loadingCollaboratorsAction,
    deleteCache,
    isOwner,
    isReadonly,
    isReadWrite,
    getCurrentRepository,
    getCurrentRepositoryName,
    getCurrentRepositoryCurrency,
    getCurrentRepositoryFormattedCurrency,
    getCurrentTimeline,
    getTimelineEnd,
    getCurrentRepositorySettings,
    getCollaboratorsList,
    getCurrenciesList,
    setCurrentRepositoryId,
    fetchRepositories,
    fetchCurrencies,
    fetchRepositoryInvites,
    fetchRepositoryCollaborators,
    createRepository,
    updateRepository,
    updateUISettings,
    exportRepository,
    deleteRepository,
    clearRepositories,
    addRepositoryInvite,
    removeRepositoryInvite,
    updateRepositoryCollaborator,
    removeRepositoryCollaborator,
    acceptRepositoryInvite,
    declineRepositoryInvite,
    updateTimeline,
    clearCache,
  }
})
