<template>
  <div
    ref="headerRef"
    class="ui-grid__header"
    :data-cell="isCell"
    :data-checked="isDataChecked"
    :class="mainClasses"
  >
    <div class="ui-grid__header-value" :class="valueClasses">
      <slot v-bind="{ columnName, displayValue }">
        {{ displayValue }}
      </slot>
    </div>
    <HeaderSort v-if="isSortVisible" v-model="sort" v-bind="{ column }" />
    <div
      v-if="isResizerVisible"
      ref="resizerRef"
      class="ui-grid__header-resizer"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, inject, ref, useTemplateRef } from 'vue'

import { Sort } from '@types'

import { GridColumn } from '../utils/types'

import useResizeService from '@/components/hooks/resize'

import HeaderSort from './HeaderSort.vue'

type Props = {
  column?: GridColumn
  isFirst: boolean
  isLast?: boolean
  isHeaderChecked?: boolean
}

type Emits = {
  resize: [column: string, width: number]
  reset: [column: string]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const sort = defineModel<Sort[]>('sort')

const disabled = inject('disabled', false)

const headerRef = useTemplateRef('headerRef')
const resizerRef = useTemplateRef('resizerRef')

const resetTrigger = ref(false)

const columnName = computed(() => props.column?.name)
const displayValue = computed(() => props.column?.caption)

const isSortVisible = computed(
  () => !disabled && props.column && !props.column.unsortable,
)

const isResizerVisible = computed(
  () => !disabled && props.column && !props.column.unresizeable,
)

const mainClasses = computed(() => ({
  'ui-grid__header--first': props.isFirst,
  'ui-grid__header--last': props.isLast,
  [`${props.column?.headerClasses}`]: props.column?.headerClasses,
}))

const valueClasses = computed(() => ({
  'ui-grid__header-value--checkbox': !props.column,
  [`${props.column?.headerValueClasses}`]: props.column?.headerValueClasses,
}))

const isCell = computed(() =>
  disabled || !props.column || props.column.silent ? undefined : 'true',
)
const isDataChecked = computed(() =>
  !isCell.value || !props.isHeaderChecked ? undefined : 'true',
)

const hanldeResize = (width: number) => {
  if (!props.column) return
  emit('resize', props.column.name, width)
}

const handleReset = () => {
  if (!props.column) return
  resetTrigger.value = true
  emit('reset', props.column.name)
  resetTrigger.value = false
}

if (!disabled && props.column) {
  useResizeService(headerRef, resizerRef, hanldeResize, handleReset)
}
</script>

<script lang="ts">
export default {
  name: 'GridHeader',
}
</script>

<style scoped lang="postcss">
.ui-grid__header {
  @apply min-h-[2.45rem];
  @apply sticky;
  @apply top-0;
  @apply flex items-center;
  @apply px-2 py-0.5;
  @apply bg-gray-100 dark:bg-gray-850;
  @apply border-b border-gray-200 dark:border-gray-700;
  @apply z-20;

  &--first {
    @apply pl-4;
  }

  &--last {
    @apply pr-4;
  }

  &[data-selected] {
    @apply before:content-[''];
    @apply before:absolute before:inset-[1px];
    @apply before:border;
    @apply before:border-indigo-300;
    @apply dark:before:border-indigo-500 dark:border-opacity-50;

    &[data-copy] {
      @apply before:border-dashed;
    }
  }

  &-value {
    @apply relative;
    @apply flex-auto;
    @apply text-gray-400 dark:text-gray-500;
    @apply font-medium uppercase;
    @apply tracking-wider;
    @apply text-xs;
    @apply text-ellipsis overflow-x-hidden;

    &--checkbox {
      @apply overflow-visible;
    }
  }

  &-resizer {
    @apply w-1.5;
    @apply absolute;
    @apply top-0.5 bottom-0.5 -right-0;
    @apply bg-indigo-300 dark:bg-indigo-500;
    @apply border-2 border-white dark:border-gray-800;
    @apply cursor-col-resize;
    @apply opacity-10 hover:opacity-100;
    @apply z-30;
  }
}

.ui-grid:not(.ui-grid--collapsed) {
  .ui-grid__header {
    &--first {
      @apply pl-6 lg:pl-8;
    }

    &--last {
      @apply pr-6 lg:pr-8;
    }
    &-value {
      &--right {
        @apply text-right;
      }
    }
  }
}
</style>
