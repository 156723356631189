<template>
  <component :is="layout">
    <router-view />
  </component>
  <AppAsyncNotification />
  <AppModalFactory :instances="modalsItems" />
  <AppIntercomLauncher v-if="!hideIntercomLauncher" />
</template>

<script setup lang="ts">
import {
  computed,
  onBeforeUnmount,
  onMounted,
  provide,
  watch,
  watchEffect,
} from 'vue'
import { useRoute } from 'vue-router'
import { Capacitor } from '@capacitor/core'
import { useDark, useLocalStorage, useTitle } from '@vueuse/core'

import { ModalFactoryInstances } from './components/App/ModalFactory/utils/types'

import { BLUR_DIGITAL_VALUES } from '@/const/storage'
import {
  ENV_SHORT_NAMES,
  IS_PROD_MODE,
  OWNER_MODE,
  READONLY_MODE,
  READWRITE_MODE,
  USER_PLAN,
} from './const/common'

import DefaultLayout from '@/layouts/Default.vue'
import EmptyLayout from '@/layouts/Empty.vue'
import MainLayout from '@/layouts/Main.vue'

import { useModalsStore } from './store/modals'
import { useRepositoriesStore } from './store/repositories'
import { useUserStore } from './store/user'

import AppAsyncNotification from '@/plugins/notification/NotificationGroup.vue'
import { AppIntercomLauncher, AppModalFactory } from '@app'

const modalsStore = useModalsStore()
const repositoriesStore = useRepositoriesStore()
const userStore = useUserStore()

const route = useRoute()

const blurDigitalValues = useLocalStorage(BLUR_DIGITAL_VALUES, false)

const modalsItems = computed(() => modalsStore.getList as ModalFactoryInstances)
const isOwner = computed(() => repositoriesStore.isOwner)
const isReadonly = computed(() => repositoriesStore.isReadonly)
const isReadWrite = computed(() => repositoriesStore.isReadWrite)
const userPlan = computed(() => userStore.getUserPlan)
const repositoryName = computed(
  () => repositoriesStore.getCurrentRepository?.name,
)
const currency = computed(
  () => repositoriesStore.getCurrentRepositoryFormattedCurrency,
)
provide('currency', currency)

const { VITE_APP_MODE } = import.meta.env

const isProdMode = ['production', 'testing'].includes(VITE_APP_MODE)

const title = computed(() => {
  const envString = isProdMode
    ? ''
    : `[${ENV_SHORT_NAMES[VITE_APP_MODE] || '??'}] `
  const repoString = repositoryName.value ? `${repositoryName.value} | ` : ''
  const titleString = route.meta.title || 'Allposit Inc.'
  return `${envString}${repoString}${titleString}`
})
useTitle(title)

provide(IS_PROD_MODE, isProdMode)
provide(OWNER_MODE, isOwner)
provide(READONLY_MODE, isReadonly)
provide(READWRITE_MODE, isReadWrite)
provide(USER_PLAN, userPlan)

const setCurrentRepository = async (id?: string) => {
  await repositoriesStore.setCurrentRepositoryId(id)
}
provide('setCurrentRepository', setCurrentRepository)

const layout = computed(() => route?.meta?.layout || 'DefaultLayout')
const hideIntercomLauncher = computed(() => route?.meta?.hideIntercomLauncher)
const isDark = useDark()

const injectCSS = (css: string) => {
  const el = document.createElement('style')
  el.innerText = css
  document.head.appendChild(el)
  return el
}

if (Capacitor.getPlatform() === 'android') {
  injectCSS(`* {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  }`)
}

watch(
  blurDigitalValues,
  value => {
    if (value) {
      document.body.classList.add('blur-numbers')
    } else {
      document.body.classList.remove('blur-numbers')
    }
  },
  { immediate: true },
)

watchEffect(() => {
  // global flags for router, menu etc
  // @ts-ignore
  window[OWNER_MODE] = isOwner.value
  // @ts-ignore
  window[READONLY_MODE] = isReadonly.value
  // @ts-ignore
  window[READWRITE_MODE] = isReadWrite.value
})

onBeforeUnmount(() => {
  // @ts-ignore
  delete window[OWNER_MODE]
  // @ts-ignore
  delete window[READONLY_MODE]
  // @ts-ignore
  delete window[READWRITE_MODE]
})

onMounted(() => {
  if (!isDark.value) return
  document.documentElement.classList.add('dark')
})
</script>

<script lang="ts">
export default {
  name: 'App',
  components: {
    DefaultLayout,
    MainLayout,
    EmptyLayout,
  },
}
</script>

<style lang="postcss">
.blur-numbers {
  .blurable-number {
    @apply blur-sm hover:blur-none;
    @apply text-inherit;

    &--md {
      @apply blur hover:blur-none;
      @apply text-inherit;
    }

    .grid-cell__amount {
      @apply text-inherit;
    }
  }
}
</style>
