<template>
  <UILayoutNoData v-bind="mainProps" @click:help="handleClickHelp">
    <template #text-description>{{ caption }}</template>
    <template v-if="!isReadonly" #default>
      <UIButton
        :icon="PlusIcon"
        v-bind="{ disabled }"
        label="Connect"
        :loading="syncing"
        loading-arrows
        full
        @click="handleClickAdd"
      />
    </template>
    <template #text-question>What is a connectors?</template>
  </UILayoutNoData>
  <AddAccount ref="addAccountRef" />
</template>

<script setup lang="ts">
import { computed, inject, useTemplateRef } from 'vue'

import { ReadonlyMode } from '@types'
import { READONLY_MODE } from '@/const'

import { useIntercom } from '@/plugins/intercom'
import { useLinkedDataConnector } from '../LinkedData/hooks/useLinkedDataConnector'

import { UIButton, UILayoutNoData } from '@ui'
import { PlusIcon } from '@heroicons/vue/24/outline'
import AddAccount from '@/views/LinkedData/Connectors/components/AddAccount.vue'

const { loading, syncing } = useLinkedDataConnector()
const intercom = useIntercom()

const isReadonly = inject<ReadonlyMode>(READONLY_MODE)

const addAccountRef = useTemplateRef('addAccountRef')

const disabled = computed(() => syncing.value || loading.value)

const mainProps = computed(() =>
  isReadonly?.value ? { inline: true, hideQuestionIcon: true } : undefined,
)

const caption = computed(() =>
  isReadonly?.value
    ? 'No accounts connected with current repository'
    : 'Get started by connecting accounts',
)

const handleClickAdd = () => {
  addAccountRef.value?.show()
}

const handleClickHelp = () => {
  intercom.help(5480491)
}
</script>
