import { computed, reactive, ref, watch } from 'vue'
import { defineStore } from 'pinia'
import { RouteLocationNormalizedLoaded, useRoute, useRouter } from 'vue-router'
import { isEqual } from 'lodash'

import { NavigationItem, NavigationPreset, UserPlan } from '@types'

import { ROUTE_NAME } from '@/const'
import {
  CHILDREN_NAVIGATION,
  DASHBOARD_NAME,
  HEADER_NAVIGATION,
} from './utils/const'

import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useContactsBunchStore } from '@/store/contacts/bunch'
import { useDashboardsSettingsStore } from '@/store/settings/dashboards'
import { useDocumentsBunchStore } from '@/store/documents/bunch'
import { useTagsBunchStore } from '@/store/tags/bunch'
import { useTransactionsBunchStore } from '@/store/transactions/bunch'
import { useUserStore } from '@/store/user'

export const useNavigationStore = defineStore('navigation', () => {
  // INIT

  const assetsBunchStore = useAssetsBunchStore()
  const contactsBunchStore = useContactsBunchStore()
  const dashboardSettingsStore = useDashboardsSettingsStore()
  const documentsBunchStore = useDocumentsBunchStore()
  const tagsBunchStore = useTagsBunchStore()
  const transactionsBunchStore = useTransactionsBunchStore()
  const userStore = useUserStore()

  const route = useRoute()
  const router = useRouter()

  const currentHeaderKey = ref()

  const headerKeys = reactive(
    Object.fromEntries(
      HEADER_NAVIGATION.map(item => [
        item.name,
        localStorage.getItem(`nav-${item.name}`),
      ]),
    ),
  )

  // GETTERS

  const getHeaderNavigation = computed(() =>
    HEADER_NAVIGATION.map(item => {
      const key = item.name as keyof typeof CHILDREN_NAVIGATION
      const children = CHILDREN_NAVIGATION[key]
      const cachedRoute = headerKeys[key]
      let routeName = item.routeName || children?.[0]?.routeName
      let routeParams
      if (cachedRoute) {
        let isJson = true
        let result
        try {
          result = JSON.parse(cachedRoute)
        } catch {
          isJson = false
        }
        if (key === DASHBOARD_NAME && isJson) {
          routeName = result?.name
          routeParams = result?.params
        } else {
          const hasTheChild = children?.find(
            child => child.routeName === cachedRoute,
          )
          const isRouteExists = router.hasRoute(cachedRoute)
          if (hasTheChild && isRouteExists) {
            routeName = cachedRoute
          }
        }
      }
      return {
        name: `${item.name}`,
        routeName,
        routeParams,
        active: currentHeaderKey.value === key,
      }
    }),
  )

  const getCurrentChildNavigation = computed(() =>
    getChildNavigation(currentHeaderKey.value),
  )

  const getBreadcrumbs = computed(() => {
    const result = []
    const parent = HEADER_NAVIGATION.find(
      item => item.name === currentHeaderKey.value,
    )
    parent && result.push(parent.name)
    if (currentHeaderKey.value === DASHBOARD_NAME) {
      const child = getDashboardNavigation.value.find(
        item =>
          findDashboardChild(item, route) ||
          findChildren(`${route.name?.toString()}`, item),
      )
      child && result.push(child.name)
    } else if (parent?.name) {
      const children = CHILDREN_NAVIGATION[parent.name]
      const child = children?.find(item =>
        findChildren(`${route.name?.toString()}`, item),
      )
      child && result.push(child.name)
    }
    return result
  })

  // ACTIONS

  const findChildren = (name: string, child: NavigationPreset) =>
    child.routeName === name || child.additional?.includes(name)

  const findDashboardChild = (
    child: NavigationItem,
    route: RouteLocationNormalizedLoaded,
  ) =>
    child.routeName === route.name &&
    JSON.stringify(child.routeParams) === JSON.stringify(route.params)

  const getDashboardNavigation = computed(() => {
    const dashboards =
      dashboardSettingsStore.getDashboards?.map(item => ({
        name: item.name || 'Unnamed',
        routeName: ROUTE_NAME.DASHBOARDS_DASHBOARD,
        routeParams: { slug: item.slug },
        active: item.slug === route.params.slug,
      })) || []
    const children =
      CHILDREN_NAVIGATION[DASHBOARD_NAME]?.filter(child => {
        const route = router.resolve({ name: child.routeName })
        return (
          !route.meta?.access ||
          (route.meta.access as UserPlan[]).includes(userStore.getUserPlan)
        )
      }).map(item => ({
        name: `${item.name}`,
        routeName: item.routeName,
        active: route.name === item.routeName,
      })) || []
    return [...dashboards, ...children]
  })

  const getChildNavigation = (key: string): NavigationItem[] | undefined => {
    if (key === DASHBOARD_NAME) {
      return dashboardSettingsStore.isEditMode
        ? undefined
        : getDashboardNavigation.value
    }
    return CHILDREN_NAVIGATION[key]?.map(item => {
      let isDirty
      switch (item.routeName) {
        case ROUTE_NAME.TRANSACTIONS:
          isDirty = transactionsBunchStore.isDirty
          break
        case ROUTE_NAME.ASSETS:
          isDirty = assetsBunchStore.isCommonDirty
          break
        case ROUTE_NAME.CURRENCIES:
          isDirty = assetsBunchStore.isCurrenciesDirty
          break
        case ROUTE_NAME.INCOME_ACCOUNTS:
          isDirty = assetsBunchStore.isIncomeAccountsDirty
          break
        case ROUTE_NAME.EXPENSE_ACCOUNTS:
          isDirty = assetsBunchStore.isExpenseAccountsDirty
          break
        case ROUTE_NAME.CONTACTS:
          isDirty = contactsBunchStore.isDirty
          break
        case ROUTE_NAME.DOCUMENTS:
          isDirty = documentsBunchStore.isDirty
          break
        case ROUTE_NAME.TAGS:
          isDirty = tagsBunchStore.isDirty
          break
      }
      return {
        name: `${item.name}`,
        routeName: item.routeName,
        active:
          route.name === item.routeName ||
          item.additional?.includes(route.name as string),
        isDirty,
      }
    })
  }

  const clear = () => {
    currentHeaderKey.value = undefined
  }

  watch(
    route,
    ({ name: routeName, params: routeParams }) => {
      let currentKey
      let currentRouteName
      currentHeaderKey.value = undefined
      if (routeName === ROUTE_NAME.DASHBOARDS_DASHBOARD) {
        currentKey = DASHBOARD_NAME
        const children = getChildNavigation(DASHBOARD_NAME)
        const current = children?.find(
          child =>
            child.routeName === routeName &&
            isEqual(child.routeParams, routeParams),
        )
        if (current) {
          currentRouteName = JSON.stringify({
            name: current.routeName,
            params: current.routeParams,
          })
        }
      } else {
        const children = Object.entries(CHILDREN_NAVIGATION)
        currentKey = HEADER_NAVIGATION.find(
          item => item.routeName === routeName,
        )?.name
        if (!currentKey) {
          for (let i = 0; i < children.length; i++) {
            const [key, data] = children[i]
            const child = data.find(item =>
              findChildren(`${routeName?.toString()}`, item),
            )
            if (child) {
              currentKey = key
              currentRouteName = child.routeName
              break
            }
          }
        }
        if (!currentKey) return
      }
      currentHeaderKey.value = currentKey
      if (currentRouteName) {
        localStorage.setItem(`nav-${currentKey}`, currentRouteName)
        headerKeys[currentKey] = currentRouteName
      } else {
        localStorage.removeItem(`nav-${currentKey}`)
        headerKeys[currentKey] = undefined
      }
    },
    { immediate: true },
  )

  return {
    getHeaderNavigation,

    getChildNavigation,
    getCurrentChildNavigation,

    getBreadcrumbs,

    clear,
  }
})
