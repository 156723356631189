<template>
  <div class="create-actions">
    <UIButton
      :icon="BanknotesIcon"
      v-bind="{ disabled }"
      variant="light"
      label="Connect Bank & Investment Accounts"
      loading-arrows
      class="create-actions__item"
      @click="handleClickAddAccount"
    />
    <UIButton
      :icon="HomeModernIcon"
      v-bind="{ disabled }"
      variant="light"
      label="Add Real Estate"
      class="create-actions__item"
      @click="handleClickCreateRealEstate"
    />
    <UIButton
      :icon="CoinbaseIcon"
      v-bind="{ disabled }"
      variant="light"
      label="Connect Crypto Accounts"
      loading-arrows
      class="create-actions__item create-actions__item--coinbase"
      @click="handleClickAddCryptoAccount"
    />
    <div v-if="syncing" class="create-actions__loading">
      <UILoading message="Your accounts are now being refreshed" />
    </div>
  </div>
  <teleport to="body">
    <AddAccount ref="addAccountRef" />
  </teleport>
</template>

<script setup lang="ts">
import { computed, markRaw, useTemplateRef } from 'vue'

import { useModalsStore } from '@/store/modals'
import { useLinkedDataConnector } from '../LinkedData/hooks/useLinkedDataConnector'

import { UIButton, UILoading } from '@ui'
import { BanknotesIcon, HomeModernIcon } from '@heroicons/vue/24/outline'
import AddAccount from '@/views/LinkedData/Connectors/components/AddAccount.vue'
import CoinbaseIcon from '@/assets/images/icons/coinbase.svg'
import RealEstate from '@/entities/transactions/wizards/RealEstate.vue'

import { useTasksService } from '@/services/tasks'

type Emits = {
  click: []
}

const emit = defineEmits<Emits>()

const { loading, syncing } = useLinkedDataConnector()

const modalsStore = useModalsStore()
useTasksService()

const addAccountRef = useTemplateRef('addAccountRef')

const disabled = computed(() => syncing.value || loading.value)

const handleClickCreateRealEstate = () => {
  emit('click')
  const modalInstance = modalsStore.init('real-estate', markRaw(RealEstate))
  modalInstance?.open(modalsStore.getZIndex())
}

const handleClickAddAccount = () => {
  addAccountRef.value?.show({ exclude: ['coinbase'] })
}

const handleClickAddCryptoAccount = () => {
  addAccountRef.value?.show({ include: ['coinbase'] })
}
</script>

<style lang="postcss">
.create-actions {
  @apply relative;
  @apply flex flex-col sm:flex-row;
  @apply gap-4;

  &__item {
    @apply w-full sm:w-32;
    @apply p-0;
    @apply flex-col;
    @apply gap-2;
    @apply aspect-square;

    .btn {
      @apply text-xs;

      &__icon {
        @apply w-10 h-10;
        @apply text-gray-400;

        & + div {
          @apply min-h-[3rem];
          @apply flex items-center justify-center;
        }
      }
    }

    &--coinbase {
      .btn__icon {
        @apply text-white dark:text-gray-800;
      }
    }
  }

  &__loading {
    @apply absolute inset-0;
    @apply flex items-center justify-center;
    @apply bg-white dark:bg-gray-800 bg-opacity-80;
  }
}
</style>
