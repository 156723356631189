<template>
  <div v-if="!isReadonly" class="connector-actions">
    <UIButton
      v-if="isManual"
      v-bind="{ loading }"
      :disabled="loading"
      label="Import"
      variant="secondary"
      loading-arrows
      @click="handleImport"
    />
    <template v-else>
      <UIButton
        :disabled="loading"
        label="Connect"
        variant="secondary"
        loading-arrows
        @click="handleReconnect"
      />
      <UIButton
        v-bind="{ loading }"
        :icon="ArrowPathIcon"
        :disabled="loading"
        label="Sync"
        variant="secondary"
        loading-arrows
        @click="handleResync"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue'

import { LinkedDataConnector, ReadonlyMode } from '@types'

import { READONLY_MODE } from '@/const/common'

import { ArrowPathIcon } from '@heroicons/vue/24/outline'
import { UIButton } from '@ui'

type Props = {
  connector: LinkedDataConnector
  loading?: boolean
}

type Emits = {
  import: [data: string]
  reconnect: [data: LinkedDataConnector]
  resync: [data: LinkedDataConnector]
}

const { connector } = defineProps<Props>()
const emit = defineEmits<Emits>()

const isManual = computed(() => connector.access_method === 'manual')

const isReadonly = inject<ReadonlyMode>(READONLY_MODE)

const handleImport = () => {
  emit('import', connector.source.toLowerCase())
}

const handleReconnect = () => {
  emit('reconnect', connector)
}

const handleResync = () => {
  emit('resync', connector)
}
</script>

<script lang="ts">
export default {
  name: 'ConnectorActions',
}
</script>

<style scoped lang="postcss">
.connector-actions {
  @apply flex items-center justify-end space-x-2;
}
</style>
