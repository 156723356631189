<template>
  <UIDropdown
    v-bind="{ items, placement }"
    :key="key"
    v-model:show="isShown"
    :model-value="repositoryId"
    id-key="id"
    value-key="name"
    data-refid="repositoriesDropdown"
    popper-class="app-select-repository"
    @click:item="handleClickItem"
  >
    <UIButton :icon="RectangleStackIcon" size="small" variant="light">
      <span class="app-select-repository__name">
        {{ currentRepository.name }}
      </span>
      <component
        :is="sharedRepositoryIcon"
        class="app-select-repository__shared-icon"
      />
    </UIButton>
    <template #item="{ item, value }">
      <SelectRepositoryItem
        v-bind="{ item, value }"
        @export="handleExport"
        @edit="handleEdit"
        @delete="handleDelete"
      />
    </template>
    <template #after="{ hide }">
      <SelectRepositoryButtons
        :callback="hide"
        @import="handleImport"
        @create="handleCreate"
      />
      <SelectRepositoryDeleteConfirm
        v-if="selectedRepo"
        :repository="selectedRepo"
        @cancel="handleCancelDelete"
        @confirm="handleCofirmDelete"
      />
    </template>
  </UIDropdown>
</template>

<script lang="ts" setup>
import { computed, inject, ref } from 'vue'
import { Placement } from 'floating-vue'

import { Repository } from '@types'

import { RectangleStackIcon, UserGroupIcon } from '@heroicons/vue/24/outline'

import { UIButton, UIDropdown } from '@ui'
import SelectRepositoryButtons from './components/SelectRepositoryButtons.vue'
import SelectRepositoryDeleteConfirm from './components/SelectRepositoryDeleteConfirm.vue'
import SelectRepositoryItem from './components/SelectRepositoryItem.vue'

type Props = {
  currentRepository: Repository
  repositoriesList: Repository[]
  placement?: Placement
}

type Emits = {
  import: []
  create: []

  export: [data: Repository, callback?: () => void]
  edit: [data: string]
  delete: [data: string]
}
const { placement = 'bottom-start', ...props } = defineProps<Props>()
const emit = defineEmits<Emits>()

const setCurrentRepository = inject<(id?: string) => void>(
  'setCurrentRepository',
)

const selectedRepo = ref<Repository>()
const isShown = ref(false)

const key = ref(0)

const repositoryId = computed(() => props.currentRepository.id)

const items = computed(() => props.repositoriesList)

const sharedRepositoryIcon = computed(() =>
  props.currentRepository.collaborators_count > 1 ? UserGroupIcon : undefined,
)

const handleClickItem = (item: Repository) => {
  if (item.id === props.currentRepository.id) return
  setCurrentRepository && setCurrentRepository(item.id)
}

const handleImport = () => {
  emit('import')
}

const handleCreate = () => {
  emit('create')
}

const handleExport = (item: Repository, callback: () => void) => {
  emit('export', item, callback)
  key.value++
}

const handleEdit = (item: Repository) => {
  emit('edit', item.id)
  key.value++
}

const handleDelete = (item: Repository) => {
  selectedRepo.value = item
}

const handleCofirmDelete = (exportBeforeDelete: boolean) => {
  if (!selectedRepo.value) return
  if (exportBeforeDelete) {
    emit('export', selectedRepo.value)
  }
  emit('delete', selectedRepo.value.id)
  selectedRepo.value = undefined
}

const handleCancelDelete = () => {
  selectedRepo.value = undefined
}
</script>

<script lang="ts">
export default {
  name: 'AppSelectRepository',
}
</script>

<style lang="postcss">
.app-select-repository {
  &__name {
    @apply max-w-14 sm:max-w-16 md:max-w-20;
    @apply truncate;

    &--shrinked {
      @apply max-w-10 sm:max-w-14 lg:max-w-24;
    }
  }
  &__shared-icon {
    @apply w-4 h-4;
    @apply shrink-0;
    @apply text-indigo-400 dark:text-indigo-300;
  }
  .ui-dropdown__items {
    @apply min-h-[7.5rem];
    @apply !max-h-[calc(100vh-10rem)];
  }
}
</style>
