import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { cloneDeep } from 'lodash'

import {
  Transaction,
  TransactionError,
} from '@/entities/transactions/utils/types'
import { PaginatedFetch, PaginatedMeta } from '@types'

import { prepareResponseError } from '../utils/helpers'

import api, { getPaginatedRequest } from '@/store/api'
import useAnalyticsStore from '@/store/analytics'
import { useRepositoriesStore } from '@/store/repositories'
import { PAGE_SIZE } from '@/const/common'

export const useTransactionsStore = defineStore('transactions', () => {
  // INIT
  const analyticsStore = useAnalyticsStore()
  const repositoriesStore = useRepositoriesStore()

  const list = ref<Transaction[]>([])
  const pagination = ref<PaginatedMeta>()

  const initFlag = ref(false)

  const loading = ref(true)
  const loadingAction = ref(false)

  const errors = ref<TransactionError[]>([])

  let abortController = new AbortController()

  const repositoryId = computed(() => repositoriesStore.currentRepositoryId)

  // GETTERS
  const getList = computed(() => list.value)
  const getPagination = computed(() => pagination.value)
  const getErrors = computed(() => errors.value)

  // SETTERS

  const setList = (data: Transaction[], meta?: PaginatedMeta) => {
    list.value = data
    pagination.value = meta
  }

  const setErrors = (data: TransactionError[]) => {
    errors.value = data
  }

  // ACTIONS

  const fetch: PaginatedFetch = async (
    options,
    store = true,
    signal?: AbortSignal,
  ) => {
    if (!repositoryId.value) return
    if (store) loading.value = true
    try {
      const { data, meta } = await getPaginatedRequest<Transaction>(
        'transactions',
        {
          repository_id: repositoryId.value,
          page_size: PAGE_SIZE,
          ...options,
        },
        signal,
      )
      if (store) {
        setList(data, meta)
        initFlag.value = true
      }
      return { data, meta }
    } catch (e) {
      initFlag.value = true
      throw Error(prepareResponseError(e))
    } finally {
      if (store) loading.value = false
    }
  }

  const fetchSingle = async (transaction_id: string) => {
    if (!repositoryId.value) return
    try {
      const { data } = await api.get(`transactions/${transaction_id}`, {
        params: {
          repository_id: repositoryId.value,
        },
      })
      return data
    } catch (e) {
      throw Error(prepareResponseError(e))
    }
  }

  const fetchSummary = async (options: { asset_ids?: string }) => {
    try {
      const rid = repositoriesStore.currentRepositoryId

      const { data } = await api.get('transactions/summary', {
        params: {
          repository_id: rid,
          ...options,
        },
      })

      return data
    } catch (e) {
      throw Error(prepareResponseError(e))
    }
  }

  const exportList = async (options: any) => {
    const params = cloneDeep(options)
    delete params.sort
    if (typeof params.filter !== 'string') {
      params.filter = JSON.stringify(params.filter)
    }
    const { data } = await api.get('transactions/export', {
      params: {
        repository_id: repositoryId.value,
        ...params,
      },
    })

    return data
  }

  const store = async (
    transaction: Transaction,
  ): Promise<Transaction | undefined> => {
    loadingAction.value = true
    try {
      const result = await api.post(`transactions`, {
        ...transaction,
        repository_id: repositoryId.value,
      })
      analyticsStore.markAsDeprecated()
      return result.data
    } catch (e) {
      throw Error(prepareResponseError(e))
    } finally {
      loadingAction.value = false
    }
  }

  const update = async (
    transaction: Transaction,
  ): Promise<Transaction | undefined> => {
    loadingAction.value = true
    try {
      const result = await api.put(
        `transactions/${transaction.id}`,
        transaction,
      )
      analyticsStore.markAsDeprecated()
      return result.data
    } catch (e) {
      throw Error(prepareResponseError(e))
    } finally {
      loadingAction.value = false
    }
  }

  const bulkStore = async (transactions: Transaction[]): Promise<void> => {
    loadingAction.value = true
    try {
      await api.post(
        `transactions?repository_id=${repositoryId.value}`,
        transactions,
      )
      analyticsStore.markAsDeprecated()
    } catch (e) {
      throw Error(prepareResponseError(e))
    } finally {
      loadingAction.value = false
    }
  }

  const bulkDestroy = async (ids: string[]): Promise<void> => {
    loadingAction.value = true
    try {
      await api.delete(`transactions`, { data: ids })
      analyticsStore.markAsDeprecated()
    } catch (e) {
      throw Error(prepareResponseError(e))
    } finally {
      loadingAction.value = false
    }
  }

  const cancel = () => {
    abortController.abort()
    abortController = new AbortController()
  }

  const clear = () => {
    initFlag.value = false
    setList([], undefined)
    setErrors([])
  }

  return {
    initFlag,
    loading,
    loadingAction,

    getList,
    getPagination,
    getErrors,

    fetch,
    fetchSingle,
    fetchSummary,
    export: exportList,

    store,
    update,

    bulkStore,
    bulkDestroy,

    cancel,
    clear,
  }
})
