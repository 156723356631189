<template>
  <div class="asset-tab__form">
    <form class="asset-tab__form--inline" @submit="onSubmit">
      <span class="asset-tab__form__caption">Symbol:</span>
      <div class="asset-tab__form__body" :class="bodyClasses">
        <EODSearch
          ref="eodInputRef"
          v-model="securityValue"
          v-bind="{ readonly }"
          :disabled="isLoading"
          placeholder="Security master ticker"
          float
          short
        />
      </div>
      <UIButton
        v-if="isSecurityInfoButtonShown"
        type="button"
        label="Security info"
        variant="secondary"
        :disabled="isLoading"
        @click="dialogFlag = true"
      />
      <UIButton
        v-if="isSaveButtonShown"
        :disabled="isLoading"
        :loading="isLoading"
        data-refid="assetSecurityMasterFormSave"
        label="Save"
      />
      <UIButton
        v-if="isCancelButtonShown"
        variant="light"
        data-refid="assetSecurityMasterFormCancel"
        label="Cancel"
        @click="onCancel"
      />
    </form>
  </div>
  <UIModal
    v-bind="{
      isOpen: dialogFlag,
      type: ModalType.SLIDEPANEL,
      title: 'Security info',
      size: 'md',
    }"
    @hide="dialogFlag = false"
  >
    <UIJsonTree v-bind="{ json }" size="xxsmall">JSON data</UIJsonTree>
  </UIModal>
  <UIModal
    :type="ModalType.ACTION_DIALOG"
    :is-open="saveDialogFlag"
    title="Warning of changes"
    size="md"
    :actions="saveDialogActions"
    @hide="saveDialogFlag = false"
  >
    <UICheckbox v-if="assetType !== formValues.asset_type" v-model="change.type"
      ><span class="font-normal text-gray-400">Change type from</span>
      {{ assetType }}
      <span class="font-normal text-gray-400">to</span>
      {{ formValues.asset_type }}</UICheckbox
    >
    <UICheckbox v-if="assetName !== formValues.asset_name" v-model="change.name"
      ><span class="font-normal text-gray-400">Change name from</span>
      {{ assetName }}
      <span class="font-normal text-gray-400">to</span>
      {{ formValues.asset_name }}</UICheckbox
    >
    <UICheckbox
      v-if="assetTicker !== formValues.asset_ticker"
      v-model="change.ticker"
      ><span class="font-normal text-gray-400">Change ticker from</span>
      {{ assetTicker }}
      <span class="font-normal text-gray-400">to</span>
      {{ formValues.asset_ticker }}</UICheckbox
    >
  </UIModal>
</template>

<script lang="ts" setup>
import {
  computed,
  inject,
  nextTick,
  onMounted,
  reactive,
  ref,
  useTemplateRef,
  watch,
} from 'vue'
import { useField, useForm } from 'vee-validate'
import { StringSchema } from 'yup'

import { AssetClass } from '..'
import { AssetSecurityInfo } from '@/entities/assets/utils/types'
import {
  AssetSecurityMaster,
  DialogActionButton,
  ModalType,
  ReadonlyMode,
} from '@types'

import { ASSET_FIELD } from '../utils/const'
import { READONLY_MODE } from '@/const/common'

import { rules } from '@/helpers/validate'

import { useAssetsStore } from '@/store/assets'

import EODSearch from '@/modules/EODSearch/EODSearch.vue'

import { UIButton, UICheckbox, UIJsonTree, UIModal } from '@ui'

type Props = {
  instance: AssetClass
}

const props = defineProps<Props>()

const assetsStore = useAssetsStore()

const eodInputRef = useTemplateRef('eodInputRef')

const securityValue = ref<AssetSecurityMaster>()

const dialogFlag = ref(false)
const saveDialogFlag = ref(false)

const change = reactive({
  type: true,
  name: true,
  ticker: true,
})

const readonly = inject<ReadonlyMode>(READONLY_MODE)

const isLoading = computed(() => assetsStore.loadingAction)

const assetLinkedSecurityId = props.instance.field<string | null>(
  ASSET_FIELD.LINKED_SECURITY_ID,
)
const assetType = props.instance.field<string>(ASSET_FIELD.TYPE)
const assetName = props.instance.field<string>(ASSET_FIELD.NAME)
const assetTicker = props.instance.field<string>(ASSET_FIELD.TICKER)

const assetSecurityInfo = props.instance.field<AssetSecurityInfo>(
  ASSET_FIELD.SECURITY_INFO,
)

const assetSecurityInfoData = computed(
  () => assetSecurityInfo.value?.data || {},
)

const isSecurityInfoButtonShown = computed(
  () =>
    securityMasterIdValue.value &&
    assetSecurityInfoData.value &&
    !meta.value.dirty &&
    meta.value.valid,
)

const isSaveButtonShown = computed(
  () => meta.value.dirty && meta.value.valid && !readonly?.value,
)

const isCancelButtonShown = computed(
  () => isSaveButtonShown.value && !isLoading.value,
)

const bodyClasses = computed(() => ({
  'asset-tab__form__body--shrinked': isCancelButtonShown.value,
}))

const json = computed(() =>
  Object.fromEntries(
    Object.entries(assetSecurityInfoData.value).filter(
      ([_, value]) => typeof value !== 'object',
    ),
  ),
)

const {
  handleSubmit,
  meta,
  resetForm,
  values: formValues,
} = useForm<{
  linked_security_id: string
  asset_type: string
  asset_name: string
  asset_ticker: string
}>({
  initialValues: {
    linked_security_id: assetLinkedSecurityId.value || '',
    asset_type: assetType.value,
    asset_name: assetName.value,
    asset_ticker: assetTicker.value,
  },
  validationSchema: {
    linked_security_id: (rules.limited as StringSchema).matches(
      /^[a-zA-Z]+(-[a-zA-Z]+)*\.(US|CC)/,
      {
        message: 'should be in the following format: X.US or X.CC',
        excludeEmptyString: true,
      },
    ),
    asset_type: () => true,
    asset_name: () => true,
    asset_ticker: () => true,
  },
})

const { value: securityMasterIdValue } = useField<string>('linked_security_id')
const { value: assetTypeValue } = useField<string>('asset_type')
const { value: assetNameValue } = useField<string>('asset_name')
const { value: assetTickerValue } = useField<string>('asset_ticker')

watch(securityValue, value => {
  if (!value) return
  securityMasterIdValue.value = value.code + '.' + value.exchange
  assetTypeValue.value = value.type
  assetNameValue.value = value.name
  assetTickerValue.value = value.code
})

const onSubmit = (e: Event) => {
  e.preventDefault()
  if (
    (assetType.value === assetTypeValue.value &&
      assetName.value === assetNameValue.value &&
      assetTicker.value === assetTickerValue.value) ||
    !securityMasterIdValue.value
  ) {
    handleSave(false)()
  } else {
    saveDialogFlag.value = true
  }
}

const handleSave = (update = true) =>
  handleSubmit(values => {
    saveDialogFlag.value = false
    assetLinkedSecurityId.value = values.linked_security_id || null
    if (change.type && update) {
      assetType.value = values.asset_type
    }
    if (change.name && update) {
      assetName.value = values.asset_name
    }
    if (change.ticker && update) {
      assetTicker.value = values.asset_ticker
    }
    props.instance.update()
    resetForm()
  })

const onCancel = async () => {
  if (assetLinkedSecurityId.value) {
    securityValue.value = {
      code: assetLinkedSecurityId.value,
      name: assetLinkedSecurityId.value,
      type: '',
      currency: '',
      exchange: '',
    }
  }
  if (readonly?.value) return
  eodInputRef.value?.cancel(
    assetLinkedSecurityId.value ? `${assetLinkedSecurityId.value}` : undefined,
  )
  await nextTick()
  resetForm()
}

const saveDialogActions = computed<DialogActionButton[]>(() => [
  {
    label: 'Save',
    onClick: handleSave(),
    buttonVariant: 'primary',
  },
])

onMounted(onCancel)
</script>

<script lang="ts">
export default {
  name: 'AssetTabSecurityMaster',
}
</script>

<style scoped>
@import url('./styles/asset.tabs.css');
</style>
