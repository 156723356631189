<template>
  <div class="document__fields">
    <component
      :is="instance.getFormName()"
      v-bind="{
        dataRefid: 'documentFormName',
        focusOnLoad: focusNameOnLoad,
        instance,
        label: DOCUMENT_FIELD_LABEL[DOCUMENT_FIELD.NAME],
        readonly,
      }"
    />
    <component
      :is="instance.getFormTagsField()"
      v-if="isSavedDocument"
      v-bind="{
        dataRefid: 'documentFormTags',
        instance,
        label: DOCUMENT_FIELD_LABEL[DOCUMENT_FIELD.TAGS],
        readonly,
      }"
    />
    <component
      :is="instance.getFormPath()"
      v-bind="{
        dataRefid: 'documentFormPath',
        focusOnLoad: focusPathOnLoad,
        instance,
        label: DOCUMENT_FIELD_LABEL[DOCUMENT_FIELD.PATH],
        readonly,
      }"
    />
    <component
      :is="instance.getFormAssetId()"
      v-bind="{
        dataRefid: 'documentFormAsset',
        focusOnLoad: focusAssetOnLoad,
        instance,
        label: DOCUMENT_FIELD_LABEL[DOCUMENT_FIELD.ASSET_ID],
        readonly,
      }"
    />
    <div class="document__buttons">
      <UIButtonClose @click="handleClose" />
      <UIButtonSave
        v-if="!readonly"
        ref="buttonSaveRef"
        data-refid="documentFormSave"
        v-bind="{
          disabled,
          loading,
        }"
        @click="handleSave"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, nextTick, provide, ref, useTemplateRef } from 'vue'

import { ReadonlyMode } from '@types'
import { DocumentClass } from '..'
import { EntityState } from '@/entities/utils/enums'

import {
  DOCUMENT_FIELD,
  DOCUMENT_FIELD_LABEL,
  DOCUMENT_ID_PREFIX,
} from '../utils/const'
import { ROUTE_NAME } from '@/const'
import { NOTIFICATION_DELAY, READONLY_MODE } from '@/const/common'

import { handleCatchedError } from '@/helpers/common'

import { useNotifications } from '@/plugins/notification'

import { UIButtonClose, UIButtonSave } from '@ui'

type Props = {
  instance: DocumentClass
}

type Emits = {
  close: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const { progress, remove, update } = useNotifications()

const loading = ref(false)

const buttonSaveRef = useTemplateRef('buttonSaveRef')
provide('document-save-focus', async () => {
  await nextTick()
  buttonSaveRef.value?.focus()
})

const readonly = inject<ReadonlyMode>(READONLY_MODE)

const disabled = computed(
  () => ![EntityState.CHANGED, EntityState.NEW].includes(props.instance.state),
)

const isSavedDocument = computed(() =>
  props.instance.id.startsWith(DOCUMENT_ID_PREFIX),
)
provide('isSavedDocument', isSavedDocument)

const focusNameOnLoad = computed(
  () =>
    !isSavedDocument.value && !props.instance.field(DOCUMENT_FIELD.NAME).value,
)

const focusPathOnLoad = computed(
  () =>
    !isSavedDocument.value &&
    !focusNameOnLoad.value &&
    !props.instance.field(DOCUMENT_FIELD.PATH).value,
)

const focusAssetOnLoad = computed(
  () =>
    !isSavedDocument.value &&
    !focusNameOnLoad.value &&
    !focusPathOnLoad.value &&
    !props.instance.field(DOCUMENT_FIELD.ASSET_ID).value,
)

const handleClose = () => {
  emit('close')
}

const handleSave = async () => {
  loading.value = true
  const isNew = props.instance.isNew
  const nid = await progress({
    message: `${isNew ? 'Creating' : 'Updating'} document`,
  })
  try {
    if (isNew) {
      await props.instance.store()
    } else {
      await props.instance.update()
    }
    await update(
      nid,
      {
        type: 'success',
        message: `Document ${props.instance.field(DOCUMENT_FIELD.NAME).value} ${
          isNew ? 'created' : 'updated'
        }`,
        link: {
          text: 'Open',
          to: {
            name: ROUTE_NAME.DOCUMENTS_ITEM,
            params: { id: props.instance.id },
          },
        },
      },
      NOTIFICATION_DELAY,
    )
    emit('close')
  } catch (e) {
    await remove(nid)
    handleCatchedError(e as string, props.instance.get())
  } finally {
    loading.value = false
  }
}
</script>

<script lang="ts">
export default {
  name: 'DocumentForm',
}
</script>

<style scoped>
@import url('./styles/documents.css');
</style>
