<template>
  <div class="mapping-rules__area">
    <div class="mapping-rules__criteria">
      <div class="mapping-rules__caption">Criteria:</div>
      <Criteria
        v-for="name in criteriaList"
        :key="name"
        v-bind="{ name }"
        v-model="criteriaModel[name]"
      />
    </div>
    <div class="mapping-rules__actions">
      <div class="mapping-rules__caption">Updates:</div>
      <Action
        v-for="name in actionsList"
        :key="name"
        v-bind="{ name }"
        v-model="actionsModel[name]"
      />
    </div>
  </div>
  <div class="mapping-rules__buttons">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import {
  TransactionRule,
  TransactionRuleActionRuleType,
  TransactionRuleType,
} from '@types'

import Action from './RulesSettingsAction.vue'
import Criteria from './RulesSettingsCriteria.vue'

const criteriaModel = defineModel<{
  [k: string]: TransactionRule['data']['criteria'][number]
}>('criteriaModel', { required: true })
const actionsModel = defineModel<{
  [k: string]: TransactionRule['data']['actions'][number]
}>('actionsModel', { required: true })

const criteriaList = computed(() => Object.values(TransactionRuleType))
const actionsList = computed(() => Object.values(TransactionRuleActionRuleType))
</script>

<script lang="ts">
export default {
  name: 'LinkedDataRulesSettings',
}
</script>

<style lang="postcss">
.mapping-rules {
  &__area {
    @apply flex-auto;
    @apply grid grid-cols-1 sm:grid-cols-2 gap-4;
    @apply px-4 md:px-6;
  }

  &__caption {
    @apply sticky top-0;
    @apply grid grid-cols-1 sm:grid-cols-2;
    @apply py-2;
    @apply bg-gray-100 dark:bg-gray-800;
    @apply text-gray-950 dark:text-gray-200;
    @apply text-xs font-medium tracking-wide uppercase;
    @apply z-10;
  }

  &__criteria,
  &__actions {
    @apply flex flex-col;
    @apply gap-4;
  }

  &__criteria &__caption {
    @apply -mx-4 sm:-mr-2 md:-ml-6;
    @apply pl-4 md:pl-6;
  }

  &__actions &__caption {
    @apply -mx-4 sm:-ml-2 md:-mr-6;
    @apply px-4 sm:pl-2 md:pr-6;
  }

  &__buttons {
    @apply sticky bottom-0;
    @apply flex items-center justify-end;
    @apply gap-2;
    @apply p-4 md:px-6 pt-2;
    @apply bg-white dark:bg-gray-800;

    .btn {
      @apply shrink-0;
    }
  }
}
</style>
